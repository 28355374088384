@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
	background: #FFFFFF;
	color: #696969;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	line-height: 2;
	font-weight: 500;
	color: #696969;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
a:hover
{
	color: #ff5722 !important;
}
::selection
{
	color: #20d34a;
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.nopadding
{
	padding: 0px !important;
}
.button
{
	display: inline-block;
	height: 51px;
	background: #283290;
}
.button::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
    background: #ff5722;
	content: '';
	z-index: 0;
	-webkit-transition: all 400ms cubic-bezier(.33,.68,.59,.99);
	-moz-transition: all 400ms cubic-bezier(.33,.68,.59,.99);
	-ms-transition: all 400ms cubic-bezier(.33,.68,.59,.99);
	-o-transition: all 400ms cubic-bezier(.33,.68,.59,.99);
	transition: all 400ms cubic-bezier(.33,.68,.59,.99);
}
.button a
{
	display: block;
	position: relative;
	line-height: 51px;
	font-size: 14px;
	font-weight: 900;
	color: #FFFFFF;
	padding-left: 39px;
	padding-right: 45px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	z-index: 10;
}
.button:hover::after
{
	height: 100%;
}
.button:hover a
{
	color: #FFFFFF !important;
}
.section_title
{
	padding-top: 17px;
}
.section_title h2
{
	text-align: left;
	font-size: 30px;
	font-weight: 500;
	color: #232323;
}
.section_title::before
{
	display: block;
	position: absolute;
	left: 1px;
	top: 0;
	width: 54px;
	height: 3px;
	background: #ff5722;
	content: '';
}
.section_title_light h2
{
	color: #FFFFFF;
}

/*********************************
3. Header
*********************************/

.header
{
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #FFFFFF;
	z-index: 100;
}
.header.scrolled
{
	top: -48px;
}

/*********************************
3.1 Top Bar
*********************************/

.top_bar
{
	width: 100%;
	background: #283290;
}
.top_bar_content
{
	width: 100%;
	height: 48px;
	padding-left: 214px;
}
.top_bar_item
{
	margin-left: 33px;
}
.top_bar_item a
{
	font-size: 14px;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.top_bar_item:first-child
{
	margin-left: 37px;
}
.emergencies
{
	height: 100%;
	background: #ff5722;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: 700;
	padding-left: 38px;
	padding-right: 38px;
}

/*********************************
3.2 Header Content
*********************************/

.header_container
{
	width: 100%;
	background: #FFFFFF;
}
.header_content
{
	width: 100%;
	height: 103px;
}
.header.scrolled .header_content
{
	height: 80px;
}
.main_nav ul li
{
	display: inline-block;
}
.main_nav ul li:not(:first-child)
{
	margin-left: 32px;
}
.main_nav ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #323232;
}
.hamburger
{
	display: none;
	cursor: pointer;
}
.hamburger i
{
	font-size: 24px;
	color: #272727;
}

/*********************************
3.3 Logo
*********************************/

.logo_container_outer
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.departments_background{
    background: url(../images/departments.jpg);
}
.cta_background{
    background: url(../images/cta.jpg);
}
.logo_container
{
	position: absolute;
	top: 0;
	left: 15px;
	width: 216px;
    height: 119px;
	background: #FFFFFF;
	box-shadow: 0px 31px 29px rgba(0,0,0,0.09);
	z-index: 10;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header.scrolled .logo_container
{
	top: 48px;
	height: 120px;
}
.logo_container a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}
.logo_content
{
	width: 100%;
	height: 100%;
	padding-left: 36px;
}
.logo_line
{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background: #ff5722;
}
.logo span
{
	color: #20d34a;
}
.logo_container a .logo:hover
{
	color: #283290;
}
.logo_text
{
	font-size: 30px;
	font-weight: 500;
	color: #283290;
	line-height: 0.75;
}
.logo_box
{
	display: inline-block;
	width: 23px;
	height: 23px;
	background: #20d34a;
	color: #FFFFFF;
	font-size: 24px;
	line-height: 23px;
	text-align: center;
	-webkit-transform: translateY(-2px);
	-moz-transform: translateY(-2px);
	-ms-transform: translateY(-2px);
	-o-transform: translateY(-2px);
	transform: translateY(-2px);
	margin-left: 1px;
}
.logo_sub
{
	font-size: 14px;
	font-weight: 400;
	color: #8f8e8e;
	padding-left: 3px;
	margin-top: 2px;
}

/*********************************
4. Menu
*********************************/

.menu_container
{
	position: fixed;
	top: 0;
	right: -50vw;
	width: 50vw;
	height: 100vh;
	background: #FFFFFF;
	z-index: 101;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-ms-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	transition: all 0.6s ease;
	visibility: hidden;
	opacity: 0;
}
.menu_container.active
{
	visibility: visible;
	opacity: 1;
	right: 0;
}
.menu
{
	position: absolute;
	top:150px;
	left: 0;
	padding-left: 15%;
}
.menu_inner
{
	width: 100%;
	height: 100%;
}
.menu_list
{
	-webkit-transform: translateY(1.5rem);
	-moz-transform: translateY(1.5rem);
	-ms-transform: translateY(1.5rem);
	-o-transform: translateY(1.5rem);
	transform: translateY(1.5rem);
	-webkit-transition: all 1000ms 400ms ease;
	-moz-transition: all 1000ms 400ms ease;
	-ms-transition: all 1000ms 400ms ease;
	-o-transition: all 1000ms 400ms ease;
	transition: all 1000ms 400ms ease;
	opacity: 0;
}
.menu_container.active .menu_list
{
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	opacity: 1;
}
.menu_item
{
	margin-bottom: 5px;
}
.menu_item a
{
	font-family: 'Roboto', sans-serif;
	font-size: 36px;
	font-weight: 700;
	color: #272727;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_item a:hover
{
	color: #ffb606;
}
.menu_close_container
{
	position: absolute;
	top: 86px;
	right: 79px;
	width: 21px;
	height: 21px;
	cursor: pointer;
	z-index: 10;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
.menu_close
{
	top: 9px;
	width: 21px;
	height: 3px;
	background: #3a3a3a;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_close::after
{
	display: block;
	position: absolute;
	top: -9px;
	left: 9px;
	content: '';
	width: 3px;
	height: 21px;
	background: #3a3a3a;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_close_container:hover .menu_close,
.menu_close_container:hover .menu_close::after
{
	background: #20d34a;
}
.menu_extra
{
	position: absolute;
	left: 0;
	bottom: 0;
	padding-left: 15%;
	padding-bottom: 25px;
}
.menu_appointment a
{
	font-size: 13px;
	color: #283290;
}
.menu_emergencies
{
	font-size: 13px;
	color: #272727;
}

/*********************************
5. Home
*********************************/
.home {
    width: 100%;
    height: 702px;
}
.home_slider_container
{
	width: 100%;
	height: 100%;
}

.home_slider_progress
{
	position: absolute;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 2px;
	background: #20d34a;
	z-index: 1;
}

.home_content
{
	position: absolute;
	top: 39.8%;
	left: 0;
	width: 100%;
}
.home_content_inner
{
	width: 550px;
}
.home_title h1
{
	font-size: 69.24px;
	font-weight: 900;
	color: #272727;
	line-height: 1.0398;
}
.home_text
{
	padding-right: 20px;
	margin-top: 10px;
	padding-left: 3px;
}
.home_text p
{
	font-size: 16px;
	font-weight: 500;
	color: #747474;
	line-height: 1.75;
}
.home_button
{
	margin-top: 34px;
	margin-left: 3px;
}

/*********************************
6. Boxes
*********************************/

.boxes
{
	width: 100%;
	background: transparent;
	z-index: 10;
	padding-bottom: 35px;
}
.box_col {
    margin-top: -24px;
}
.logo_text img{
    width: 175px;
}
.box
{
	width: 100%;
	height: 287px;
	background: #283290;
	padding-left: 42px;
	padding-top: 29px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.box::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
    background: #ff5722;
	content: '';
}
.box:hover
{
	box-shadow: 0px 15px 49px rgba(0,0,0,0.59);
}
.box_title
{
	font-size: 20px;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 11px;
}
.working_hours
{
	padding-top: 29px;
	padding-right: 60px;
}
.working_hours_list
{
	margin-top: 40px;
}

.working_hours_list ul li
{
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
}
.working_hours_list ul li:not(:last-child)
{
	margin-bottom: 15px;
}
.box_icon
{
	width: 37px;
	height: 37px;
}
.box_icon img
{
	max-width: 100%;
}
.box_text
{
	font-size: 14px;
	line-height: 2.14;
	color: #FFFFFF;
	font-weight: 400;
	margin-top: 23px;
}
.box_phone
{
	font-size: 30px;
	font-weight: 400;
	color: #ff5722;
	margin-top: 20px;
}
.box_appointments
{
	padding-right: 30px;
}
.box_emergency
{
	padding-right: 30px;
}
.box_emergency_text
{
	font-size: 14px;
	line-height: 2.14;
	color: #FFFFFF;
	font-weight: 400;
	margin-top: 18px;
}

/*********************************
7. About
*********************************/

.about
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 92px;
	padding-bottom: 92px;
}
.about_text
{
	margin-top: 34px;
}
.about_button
{
	margin-top: 45px;
}
.about_image
{
	position: absolute;
	right: 0;
	bottom: 0;
}
.about_image img
{
	max-width: 100%;
}

/*********************************
8. Departments
*********************************/

.departments
{
	width: 100%;
	background: transparent;
	padding-top: 75px;
	padding-bottom: 65px;	
}
.departments_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.departments_row
{
	margin-top: 79px;
}
.dept_col
{
	margin-bottom: 30px;
}
.dept
{
	width: 100%;
	height: 100%;
}
.dept_image
{
	width: 100%;
	display: flex;
}
.dept_image img
{
	max-width: 100%;
    height: auto;
    aspect-ratio: 3/2;
    object-fit: contain;
    object-position: bottom;
}
.dept_content
{
	padding-top: 48px;
	padding-bottom: 55px;
	background: #FFFFFF;
}
.dept_content::after
{
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	background: #ff5722;
	content: '';
}
.dept_title
{
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	color: #232323;
}
.dept_subtitle
{
	font-size: 14px;
	font-weight: 400;
	color: #696969;
	margin-top: 5px;
}
.dept_text p
{
	color: #FFFFFF;
	-webkit-transform: translateY(-9px);
	-moz-transform: translateY(-9px);
	-ms-transform: translateY(-9px);
	-o-transform: translateY(-9px);
	transform: translateY(-9px);
}
.dept_button
{
	position: absolute;
	bottom: 0;
	left: 0;
	background: #FFFFFF;
}


/*********************************
9. Services
*********************************/

.services
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 95px;
	padding-bottom: 65px;
}
.services_row
{
	margin-top: 95px;
}
.service_col
{
	margin-bottom: 30px;
}
.service
{
	width: 100%;
	background: #f5f7fa;
	padding-top: 46px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 57px;
}
.service:hover
{
	background: #283290;
}
.service::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background: #20d34a;
	content: '';
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.service:hover::after
{
	visibility: visible;
	opacity: 1;
}
.service_icon
{
	width: 61px;
	height: 61px;
	margin-left: auto;
	margin-right: auto;
}
.service_icon svg
{
	width: 61px;
	height: 61px;
}
.service_icon img
{
	max-width: 100%;
}
.svg path,
.svg rect,
.svg polygon
{
	fill: #20d34a;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.service:hover .svg path,
.service:hover .svg rect,
.service:hover .svg polygon
{
	fill: #FFFFFF;
}
.service_text
{
	margin-top: 16px;
}
.service_text p
{
	color: #696969;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.service_title
{
	font-size: 20px;
	font-weight: 700;
	color: #232323;
	padding-top: 41px;
	margin-top: 26px;
}
.service_title::before
{
	display: block;
	position: absolute;
	left: 50%;
	top: 0;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 54px;
	height: 3px;
	background: #20d34a;
	content: '';
}
.service:hover .service_title
{
	color: #FFFFFF;
}
.service:hover .service_text p
{
	color: rgba(255,255,255,0.65);
}

/*********************************
10. CTA
*********************************/

.cta
{
	width: 100%;
	padding-top: 131px;
	padding-bottom: 116px;
}
.cta_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.cta_content
{
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}
.cta_content h2
{
	font-size: 48px;
	font-weight: 700;
	color: #FFFFFF;
}
.cta_content p
{
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: -3px;
}
.cta_button
{
	margin-top: 38px;
}

/*********************************
11. Footer
*********************************/

.footer
{
	width: 100%;
}
.footer_container
{
	width: 100%;
	background: #060b3a;
	padding-bottom: 77px;
}
.footer_logo_container
{
	width: 100%;
	height: 98px;
	background: #FFFFFF;
}
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.font-500{
	font-weight: 500;
}
.footer_logo_container::after
{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background:  #ff5722;
	content: '';
}
.social-container svg{
	width: 10px;
}
.ggoogle{
	width: 20px !important;

}
.footer_logo_container a
{
	display: block;
	width: 100%;
	height: 100%;
}
.opt_wraper{
	width: 100%;
	min-height: 320px;
}
.personality_test_wraper{
	height: 100vh;
}
.footer_logo_container .logo_content
{
	display: inline-block;
	padding-left: 0;
	width: auto;
	height: auto;
}
.footer_logo_container .logo
{
	margin-top: 10px;
}
.footer_about_text
{
	margin-top: 36px;
}
.footer_about_text p
{
	color: #b2b2b2;
}
.footer_about_list
{
	margin-top: 32px;
}
.footer_about_list li:not(:last-child)
{
	margin-bottom: 9px;
}
.footer_about_icon
{
	display: inline-block;
	width: 31px;
	height: 31px;
	background: #ff5722;
	text-align: center;
	vertical-align: middle;
}
.footer_about_icon img
{
	position: absolute;
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.footer_about_list li span
{
	font-size: 14px;
	font-weight: 500;
	color: #b2b2b2;
	padding-left: 10px;
}
.footer_column
{
	padding-top: 138px;
}
.footer_title
{
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
}
.footer_links ul
{
	-webkit-columns: 2;
	-moz-columns: 2;
	columns: 2;
	margin-top: 35px;
}
.footer_links ul li:not(:last-child)
{
	margin-bottom: 20px;
}
.footer_links ul li a
{
	font-size: 14px;
	font-weight: 500;
	color: #b2b2b2;
}
.footer_news ul
{
	margin-top: 35px;
}
.footer_news_title a
{
	font-size: 15px;
	font-weight: 500;
	color: #b2b2b2;
}
.footer_news_date
{
	font-size: 14px;
	font-weight: 500;
	color: #ff5722;
	margin-top: 7px;
}
.footer_news ul li:not(:last-child)
{
	margin-bottom: 38px;
}
.copyright
{
	width: 100%;
	background: #020523;
}
.copyright_content
{
	width: 100%;
	height: 62px;
}
.cr
{
	font-size: 14px;
	font-weight: 500;
	color: #97979c;
}
.footer_social ul
{
	margin-right: -5px;
}
.footer_social ul li
{
	display: inline-block;
}
.footer_social ul li:not(:last-child)
{
	margin-right: 19px;
}
.footer_social ul li a i
{
	color: #20d34a;
	padding: 5px;
	font-size: 14px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_social ul li a i:hover
{
	color: #FFFFFF;
}
.personality__body{
	overflow: auto !important;
}
.personality_test_wraper .sign-form h3 {
	text-align: start;
}

.signin__therapist .nav-tabs .nav-link {
	background-color: #c8ccd0;
    color: #333 !important;
    border: none !important;
    border-radius: 23px !important;
    padding: 15px;
}
  
 .signin__therapist .nav-tabs .nav-link:hover {
	background-color: #ff5722 !important; /* Custom color on hover */
	color: #fff !important; /* Text color on hover */
  }
  
 .signin__therapist .nav-tabs .nav-link.active {
	background-color: #ff5722 !important; /* Active tab background color */
	color: #fff !important; /* Active tab text color */
  }
  .signin__therapist .nav-tabs{
	border: none;
	justify-content: center;
  }
  .signin__therapist .nav-tabs li{
	margin-right: 10px;
  }

  .button button {
    display: block;
    position: relative;
    line-height: 51px;
    font-size: 14px;
    font-weight: 900;
    color: #FFFFFF;
    padding-left: 39px;
    padding-right: 45px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    z-index: 10;
}
.payment_detail_wraper input::placeholder{
	color: white !important;
}
.custom_btn::after {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #ff5722;
    content: '';
    z-index: 0;
    transition: all 400ms cubic-bezier(.33,.68,.59,.99);
}
.custom_btn {
    display: block;
    position: relative;
    font-size: 14px;
    font-weight: 900;
    color: #FFFFFF;
    padding-left: 39px;
    padding-right: 45px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    z-index: 10;
	cursor: pointer;
	display: inline-block;
    height: 51px;
    background: #283290;
}
.custom_btn:hover span{
	color: white;
}
.custom_btn:hover::after {
    height: 100%;
}
.custom_btn span{
	z-index: 9;
    position: relative;
}
.profli_icon{
	width: 40px;
}
.dept_image{
	height: 300px;
}
.dept{
	padding-right: 20px;
}
.slick-prev:before, .slick-next:before {
    font-size: 50px;
}
.slick-prev {
    left: -62px;
}

