@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.box
	{
		height: 313px;
	}
	.box_phone
	{
		font-size: 24px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.main_nav
	{
		display: none;
	}
	.logo_container
	{
		width: 181px;
	}
	.logo_content
	{
		padding-left: 28px;
	}
	.logo_text
	{
		font-size: 24px;
	}
	.logo_sub
	{
		font-size: 12px;
		font-weight: 400;
	}
	.top_bar_content
	{
		padding-left: 166px;
	}
	.top_bar_item
	{
		margin-left: 27px;
	}
	.top_bar_item a
	{
		font-size: 12px;
	}
	.emergencies
	{
		font-size: 12px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.logo_box
	{
		width: 18px;
		height: 18px;
		line-height: 18px;
		font-size: 18px;
		-webkit-transform: translateY(-1px);
		-moz-transform: translateY(-1px);
		-ms-transform: translateY(-1px);
		-o-transform: translateY(-1px);
		transform: translateY(-1px);
	}
	.hamburger
	{
		display: block;
	}
	.home_title h1
	{
		font-size: 56px;
	}
	.boxes
	{
		padding-top: 95px;
	}
	.box_col
	{
		margin-top: 0;
	}
	.box_col:not(:last-child)
	{
		margin-bottom: 30px;
	}
	.box
	{
		height: auto;
		padding-bottom: 40px;
	}
	.about_image
	{
		position: relative;
		right: auto;
		bottom: auto;
		text-align: center;
		margin-top: 55px;
	}
	.footer_column
	{
		padding-top: 75px;
	}
	.copyright_content
	{
		height: auto;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.footer_social ul
	{
		margin-right: 0;
		margin-left: -5px;
		margin-top: 15px;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.top_bar
	{
		display: none;
	}
	.logo_container
	{
		height: 127px;
	}
	.logo
	{
		margin-top: 9px;
	}
	.home_content_inner
	{
		width: 100%;
	}
	.dept_text
	{
		margin-top: 15px;
	}
	.dept_button
	{
		position: relative;
		margin-top: 20px;
	}
	.cta_content h2
	{
		font-size: 36px;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	p
	{
		font-size: 13px;
	}
	.header.scrolled
	{
		top: 0px !important;
	}
	.header_content,
	.header.scrolled .header_content
	{
		height: 60px;
	}
	.header.scrolled .logo_container
	{
		top: 0;
	}
	.header.scrolled .logo_container
	{
		height: 77px;
	}
	.hamburger i
	{
		font-size: 20px;
	}
	.logo_container
	{
		width: 141px;
		height: 77px;
	}
	.logo_content
	{
		padding-left: 20px;
	}
	.logo_text
	{
		font-size: 16px;
	}
	.logo_box
	{
		width: 12px;
		height: 12px;
		line-height: 12px;
		font-size: 12px;
	}
	.logo_sub
	{
		font-size: 10px;
		padding-left: 1px;
	}
	.menu
	{
		top: 90px;
	}
	.menu_close_container
	{
		top: 20px;
		right: 20px;
	}
	.home
	{
		height: 100vh;
	}
	.home_content
	{
		top: calc(50% + 34px);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.home_title h1
	{
		font-size: 36px;
	}
	.home_text p
	{
		font-size: 13px;
	}
	.menu_container
	{
		width: 100vw;
		right: -100vw;
	}
	.menu_item a
	{
		font-size: 20px;
	}
	.section_title h2
	{
		font-size: 24px;
	}
	.button
	{
		height: 41px;
	}
	.button a
	{
		font-size: 11px;
		line-height: 41px;
		padding-left: 29px;
		padding-right: 34px;
	}
	.box
	{
		padding-left: 25px;
	}
	.working_hours
	{
		padding-right: 30px;
	}
	.cta_content h2
	{
		font-size: 28px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	.home_title h1
	{
		font-size: 28px;
	}
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}