
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.abouts_wrapper .parallax-window
{
    min-height: 400px;
    background: transparent;
}
.nopadding
{
	padding: 0px !important;
}
.button
{
	display: inline-block;
	height: 51px;
	background: #283290;
}

.section_title
{
	padding-top: 17px;
}
.section_title h2
{
	font-size: 30px;
	font-weight: 500;
	color: #232323;
}

.section_title_light h2
{
	color: #FFFFFF;
}



/*********************************
5. Home
*********************************/

.abouts_wrapper .home
{
	width: 100%;
	height: 473px;
}
.abouts_wrapper .home_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.abouts_wrapper .home_container
{
	position: absolute;
	left: 0;
	bottom: 118px;
	width: 100%;
}
.abouts_wrapper .home_title
{
	font-size: 30px;
	font-weight: 900;
	color: #FFFFFF;
}
.abouts_wrapper .home_title span {
    color: #ff5722;
}
.breadcrumbs
{
	margin-top: -2px;
}
.breadcrumbs ul li
{
	display: inline-block;
	position: relative;
}
.breadcrumbs ul li:not(:last-child)::after
{
	display: inline-block;
	position: relative;
	content: '/';
	margin-left: 7px;
	margin-right: 2px;
}
.breadcrumbs ul li,
.breadcrumbs ul li a
{
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}

/*********************************
6. About
*********************************/

.about
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 96px;
	padding-bottom: 94px;
}
.about_text
{
	margin-top: 26px;
	padding-right: 95px;
}
.loaders_container
{
	margin-top: 85px;
}
.loader
{
	width: 118px;
	height: 118px;
	margin: 0 auto;
}
.loader span
{
	position: absolute;
	left: 50%;
	bottom: -42px;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	font-size: 18px;
	font-weight: 500;
	color: #232323;
	white-space: nowrap;
}
.about_button
{
	margin-top: 129px;
}
.abouts_wrapper .box
{
	width: 100%;
	height: 287px;
	background: #283290;
	padding-left: 42px;
	padding-top: 29px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.abouts_wrapper .box:not(:last-child)
{
	margin-bottom: 34px;
}
.box::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background: #20d34a;
	content: '';
}
.box:hover
{
	box-shadow: 0px 15px 49px rgba(0,0,0,0.59);
}
.box_title
{
	font-size: 20px;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 11px;
}
.abouts_wrapper .working_hours
{
	padding-top: 29px;
	padding-right: 60px;
}
.working_hours_list
{
	margin-top: 40px;
}
.working_hours_list ul li
{
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
}
.working_hours_list ul li:not(:last-child)
{
	margin-bottom: 15px;
}
.box_icon
{
	width: 37px;
	height: 37px;
}
.box_icon img
{
	max-width: 100%;
}
.box_text
{
	font-size: 14px;
	line-height: 2.14;
	color: #FFFFFF;
	font-weight: 400;
	margin-top: 23px;
}
.box_phone
{
	font-size: 30px;
	font-weight: 400;
	color: #20d34a;
	margin-top: 20px;
}
.box_appointments
{
	padding-right: 30px;
}
.box_emergency
{
	padding-right: 30px;
}
.box_emergency_text
{
	font-size: 14px;
	line-height: 2.14;
	color: #FFFFFF;
	font-weight: 400;
	margin-top: 18px;
}

/*********************************
7. Testimonials
*********************************/

.testimonials
{
	width: 100%;
	padding-top: 94px;
	padding-bottom: 95px;
}
.testimonials_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.test_row
{
	margin-top: 57px;
}
.testimonial
{
	width: 100%;
	margin-bottom: 20px;
}
.test_icon
{
	width: 45px;
	height: 45px;
	background: #20d34a;
}
.test_text
{
	font-size: 18px;
	font-weight: 400;
	color: #FFFFFF;
	font-style: italic;
	margin-top: 14px;
	line-height: 1.77;
}
.test_author
{
	margin-top: 16px;
}
.test_author_name img
{
	width: 65px;
	height: 65px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 10px;
}
.test_author_image  img
{
	max-width: 100%;
}
.test_author_name a
{
	font-size: 16px;
	font-weight: 400;
	color: #FFFFFF;
	font-style: italic;
}
.test_author_name span
{
	color: #20d34a;
	font-style: italic;
}

/*********************************
8. Team
*********************************/

.team
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 95px;
	padding-bottom: 89px;
}
.team_row
{
	margin-top: 74px;
}
.team_member
{
	width: 100%;
	padding-right: 20px;
}
.team_member_image
{
	width: 100%;
	height: 337px;
}
.team_member_image img
{
	max-width: 100%;
	height: auto;
}
.team_member_info
{
	width: 100%;
	text-align: center;
	margin-top: 20px;
}
.team_member_name a
{
	font-size: 18px;
	font-weight: 500;
	color: #232323;
}
.team_member_title
{
	font-size: 14px;
	font-weight: 400;
	color: #696969;
	margin-top: 9px;
}
.team .slick-prev:before{
	color: #283290;
}
.team .slick-next:before{
	color: #283290;

}
/*********************************
9. CTA
*********************************/

.abouts_wrapper .cta
{
	width: 100%;
	padding-top: 131px;
	padding-bottom: 116px;
}
.abouts_wrapper .cta_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.abouts_wrapper .cta_content
{
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}
.abouts_wrapper .cta_content h2
{
	font-size: 48px;
	font-weight: 700;
	color: #FFFFFF;
}
.abouts_wrapper .cta_content p
{
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: -3px;
}
.abouts_wrapper .cta_button
{
	margin-top: 38px;
}
.text-start{
    text-align: start;
}