.bg_service {
    position: relative;
    background: url(../../../assets/images/medical-bg.jpg);
    width: 100%;
    height: 100%;
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.bg_service::after {
    content: "";
    background: rgb(0 74 173 / 50%);
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
        }
.contact-wrap {
    background: rgb(40 50 144 / 50%);
    background: -o-linear-gradient(-45deg, #7f279c 0%, #2e279d 100%);
    z-index: 9;
    color: white;
 
}
.form-group {
    margin-bottom: 1rem;
}
#contactForm .form-control {
    font-size: 16px;
    color: white !important;
}
.contact-wrap ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.contactForm .form-control {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
}
.contact-wrap .form-control{
    color: white !important;
}
.form-control {
    height: 52px;
    background: transparent !important;
    font-size: 14px;
    border-radius: 0px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: auto;
}
.contactForm input::placeholder{
        color: white;
}
option{
    color: black;
}
.contactForm select{
    color: white !important; 
}
.disabled-option{
    background-color: #b1aeae;
    opacity: 0.6;
}