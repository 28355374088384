.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #2d4059!important;
}

.btn-info {
    --bs-btn-color: #000;
    --bs-btn-bg: #ff5722;
    --bs-btn-border-color: #ff5722;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ff5722;
    --bs-btn-hover-border-color: #ff5722;
    --bs-btn-focus-shadow-rgb: 11,172,204;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ff5722;
    --bs-btn-active-border-color: #ff5722;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ff5722;
    --bs-btn-disabled-border-color: #ff5722;
}

.red-error{
	line-height: 4px;
	color:rgb(255, 60, 0);
	margin:0px;
	font-size: 12px;
	padding: 0px;
}

.order-btn{
    border-radius: 20px;
	border: 1px solid #0e141b;
	background-color: #0e141b;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.order-btn:hover{
	background-color:#ff5722;
	border-color: #ff5722;
}



.availability_wrap .card {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 36px;
    margin: 0 auto;
    border: none;
}
 
.availability_timing {
    border: 1px solid #004aad17;
    padding: 7px;
    border-radius: 7px;
    margin:0 16px 0 0;
    position: relative;
    width: 160px;
    background: #004aad17;
    flex: 0 0 178px;
    text-align: center;
}
.cross_wrapper {
    position: absolute;
    width: 20px;
    background: rgba(255, 0, 0, .8117647058823529);
    color: #fff;
    border-radius: 20px;
    height: 20px;
    top: -10px;
    right: 0;
    cursor: pointer;
}
.cross_wrapper svg {
    width: 14px;
    height: 13px;
    color: #fff;
    opacity: 1; 
}
.cross_wrapper, .plus_img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.availability_days {
    width: 150px;
    flex: 0 0 150;
}
.availability_days h6{
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 500;
    margin-left: 0;
    color: #726f6f;
    width: 100px;
}

.availability_item {
    align-items: center;
    display: flex;
    width: auto;
    min-height: 70px;
    border: 1px solid #004aad;
    padding: 22px 15px;
    border-radius: 9px;
    overflow: auto;
    margin-bottom: 19px;
}

.form-label {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-left: 0;
    color:#726f6f;
    text-transform: capitalize;
}
.add_availability_modal .modal-header .close { 
    opacity: 1;
    color: white;
    font-size: 32px;
    padding: 0;
    padding-right: 20px;
}
.add_availability_modal .modal-header { 
    background: #004aad;
    text-align: center;
    color: white;
    margin: -2rem 1rem 1rem;
    padding: 1.2rem;
    border: none;
    border-radius: 10px;
    justify-content: center;
    /* margin: 0 auto; */
}
/* scrollbar */
::-webkit-scrollbar {width: 6px;height: 6px; }                    
::-webkit-scrollbar-track {background: white; border-radius: 6px;}        
::-webkit-scrollbar-thumb {border-radius: 6px;background:  #004aad; }