@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

.sign-form{
    background-color: #2d4059;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	margin: 0 auto;
}

.arrow {
	border: solid black;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin-left:20px;
}

.sign-form h1{
	color:#ff5722;
	font-weight: bold;
	margin: 0;
	text-align: center;
	font-size:xx-large;
}

.sign-form span {
	font-size: 12px;
}

.sign-form a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.sign-form .sign-body button {
	border-radius: 20px;
	border: 1px solid #222831;
	background-color: #222831;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-top:20px;
}

.sign-form  button:hover{
	background-color:#ff5722;
	border-color: #ff5722;
}

.sign-form button:active {
	transform: scale(0.95);
}

.sign-form button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.sign-form form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	text-align: center;
}

.sign-form input {
	background-color: #eee;
	border: none;
	padding: 8px 15px;
	margin: 8px 0;
	width: 100%;
}

.sign-body {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 600px;
	height: auto;
	margin-top: 30px;
	margin-bottom: 30px;
    padding-top:20px;
	padding-bottom: 20px;
}


.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.red-error{
	line-height: 4px;
	color:rgb(255, 60, 0);
	margin:0px;
	font-size: 12px;
	padding: 0px;
}

@media(max-width:630px){
	.sign-body {
		width: 420px;
	}
}
@media(max-width:440px){
	.sign-body {
		width: 320px;
	}
}
