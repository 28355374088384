body{background-color:#f5f5f5}
.main_404_wrapper{padding:0 6%;display:flex;align-items:center;justify-content:space-between}
.heading_404 h1{font-size:20vw;margin:0;font-family:cursive}
.heading_404 p{font-size:24px;margin:0;padding-left:30px;text-align:center}
.back_404_btn{margin-top:25px;text-align:center}
.back_404_btn button{background-color:#00adb9;border:none;padding:15px 20px;border-radius:50px;font-size:18px;color:#fff;font-weight:700}
.doctor_image{height:550px;width:550px}
.doctor_image img{height:100%;width:100%}

/* loader */
.loader-wrapper {
    position: absolute;left: 0;right: 0;display: flex;align-items: center;justify-content: center;background-color: rgb(211 210 207 / 50%);height: 100vh;z-index: 99;
}
.ml-loader{position:relative;width:70px;height:70px}
.ml-loader div{-webkit-transform-origin:32px 32px;-ms-transform-origin:32px 32px;transform-origin:32px 32px;-webkit-animation:1.2s opaque ease-in-out infinite both;animation:1.2s opaque ease-in-out infinite both}
.ml-loader div::after{content:"";display:block;position:absolute;top:0;left:30px;width:5px;height:18px;border-radius:10px;background-color:#004aad}
.ml-loader div:nth-child(1){-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0)}
.ml-loader div:nth-child(2){-webkit-transform:rotate(30deg);-ms-transform:rotate(30deg);transform:rotate(30deg);-webkit-animation-delay:.1s;animation-delay:.1s}
.ml-loader div:nth-child(3){-webkit-transform:rotate(60deg);-ms-transform:rotate(60deg);transform:rotate(60deg);-webkit-animation-delay:.2s;animation-delay:.2s}
.ml-loader div:nth-child(4){-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);-webkit-animation-delay:.3s;animation-delay:.3s}
.ml-loader div:nth-child(5){-webkit-transform:rotate(120deg);-ms-transform:rotate(120deg);transform:rotate(120deg);-webkit-animation-delay:.4s;animation-delay:.4s}
.ml-loader div:nth-child(6){-webkit-transform:rotate(150deg);-ms-transform:rotate(150deg);transform:rotate(150deg);-webkit-animation-delay:.5s;animation-delay:.5s}
.ml-loader div:nth-child(7){-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);-webkit-animation-delay:.6s;animation-delay:.6s}
.ml-loader div:nth-child(8){-webkit-transform:rotate(210deg);-ms-transform:rotate(210deg);transform:rotate(210deg);-webkit-animation-delay:.7s;animation-delay:.7s}
.ml-loader div:nth-child(9){-webkit-transform:rotate(240deg);-ms-transform:rotate(240deg);transform:rotate(240deg);-webkit-animation-delay:.8s;animation-delay:.8s}
.ml-loader div:nth-child(10){-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg);-webkit-animation-delay:.9s;animation-delay:.9s}
.ml-loader div:nth-child(11){-webkit-transform:rotate(300deg);-ms-transform:rotate(300deg);transform:rotate(300deg);-webkit-animation-delay:1s;animation-delay:1s}
.ml-loader div:nth-child(12){-webkit-transform:rotate(330deg);-ms-transform:rotate(330deg);transform:rotate(330deg);-webkit-animation-delay:1.1s;animation-delay:1.1s}
.ml-loader div:nth-child(13){-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);-webkit-animation-delay:1.2s;animation-delay:1.2s}
@-webkit-keyframes opaque{0%,100%,40%{opacity:.1}
40%{opacity:1}}
@keyframes opaque{0%,100%,40%{opacity:.1}
40%{opacity:1}}

  /* responsive */
  
@media (max-width:1367px){.doctor_image{height:500px;width:500px}}
@media (max-width:1280px){.doctor_image{height:450px;width:450px}.heading_404 h1{font-size:18vw}}
@media (max-width:1199px){.doctor_image{height:400px;width:400px}}
@media (max-width:991px){.doctor_image{height:300px;width:300px}.heading_404 h1{font-size:15vw;text-align:center}.main_404_wrapper{height:100vh}}
@media (max-width:767px){.heading_404 h1{font-size:12vw;text-align:center}.doctor_image{height:250px;width:250px}}
@media (max-width:575px){.main_404_wrapper{flex-wrap:wrap;text-align:center;justify-content:center}.doctor_image{height:100%;width:100%}.back_404_btn button{width:100%}.back_404_btn{margin-top:40px;text-align:center}.heading_404 h1{font-size:16vw;text-align:center}}
@media (max-width:480px){.doctor_image{height:300px;width:300px}}