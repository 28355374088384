button {
  background-color: #2d4059;
  padding: 8px 16px;
  color: white;
  border-radius: 18px;
  cursor: pointer;
  border: none;
}

.tick {
  color: #2d4059;
}

.cross {
  color: #ff5722;
}

.appointment-table td.btns, .appointment-table td.tick, .appointment-table td.cross {
  text-align: center;
  vertical-align: middle;
}


.appointment-container {
  margin: 0 auto;
  margin-top: 116px;
  overflow-x: auto;
  text-align: center;
  width: 100%;
  margin-left: 220px;
}

.appointments-section {
  margin-bottom: 7%;
}

.appointments-section:last-child {
  margin-bottom: 15%;
}

.appointment-table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.appointments-section h2 {
  text-transform: capitalize;
  color: #ff5722;
  text-align: center;
  font-weight: 700;
  margin-top: 20px;
}

.appointment-table th,
.appointment-table td {
  border: 1px solid #ddd;
  padding: 16px;
  text-align: left;
}

.appointment-table th {
  background-color: #004aad;
}

.appointment-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.appointments-section .table-striped td{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.action_wraper {
  display: flex;
  align-items: center;
}
.action_wraper a {
  margin-right: 10px;
}
.action_wraper button{
  background-color: red;
}
@media (max-width: 980px) {
  .appointment-table {
    width: 80%;
    font-size: 14px;
  }

  .appointments-section h2 {
    font-size: 28px;
  }
}

@media (max-width: 1310px) {
  .appointment-table {
    width: 800px;
  }
}

@media (max-width: 980px) {
  .appointment-table {
    width: 600px;
    font-size: 12px;
  }

  .appointments-section h2 {
    font-size: 24px;
  }

  .appointment-table th,
  .appointment-table td {
    font-size: 10px;
  }

  .appointment-table th {
    font-size: 12px;
  }
}

@media (max-width: 790px) {
  .appointment-table {
    width: 450px;
    font-size: 10px;
  }

  .appointments-section h2 {
    font-size: 22px;
  }
}

@media (max-width: 670px) {
  .appointment-table {
    width: 250px;
    margin-left: 100px;
    font-size: 8px;
  }

  .appointments-section h2 {
    font-size: 20px;
  }

  .appointment-table th,
  .appointment-table td {
    font-size: 6px;
  }
}
