@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

.login-form{
    background-color: #2d4059;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	margin: 0 auto;
    height:100vh;
}

.arrow {
	border: solid black;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin-left:20px;
}

.login-form h1, h2{
	color:#ff5722;
	font-weight: bold;
	margin: 0;
	text-align: center;
}




.login-form span {
	font-size: 12px;
}

.login-form a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.login-form button {
	border-radius: 20px;
	border: 1px solid #222831;
	background-color: #222831;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.login-form button:hover{
	background-color:#ff5722;
	border-color: #ff5722;
}

.login-form button:active {
	transform: scale(0.95);
}

.login-form button:focus {
	outline: none;
}

.login-form button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.hidden-btn{
	display:none;
	border-radius: 20px;
	border: 1px solid #222831;
	background-color: #222831;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.login-form form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.login-form input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.form-body {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 620px;
    width: 900px;
    min-height: 550px;
    padding-top: 70px;
}
	
.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #222831;
	background: -webkit-linear-gradient(to right, #222831, #222831);
	background: linear-gradient(to right, #222831, #222831);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}
.relative{
	position: relative;
}
.eye_button {
    position: absolute;
    top: -17px;
    right: 16px;
}
.eye_button button{
	background-color: transparent !important;
	border: none !important;
	padding: 0 !important;
}
.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.red-error{
	line-height: 4px;
	color:rgb(255, 60, 0);
	margin-top:5px;
	margin-bottom:15px;
	font-size: 12px;
	padding: 0px;
}

@media(max-width:930px){
	.form-body {
		width: 700px;
		min-height: 500px;
	}
}

@media(max-width:740px){
	.form-body {
		width: 600px;
		min-height: 500px;
	}
}
@media(max-width:640px){
	.form-body {
		width: 400px;
		min-height: 570px;
	}	
	.sign-up-container {
		display:none;
	}
	.overlay-right {
		display:none;
	}
	.overlay-container {
		width: 0%;
	}
	.sign-in-container{
		width:100%;
	}
	.hidden-btn{
		display:block;
	}
}
@media(max-width:420px){
	.form-body {
		width: 330px;
		min-height: 570px;
		margin:0 auto;
	}
	.login-form{
		align-items: baseline;
	}
}