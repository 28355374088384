
.contact__wrap .home
{
	width: 100%;
	height: 473px;
}
.contact__wrap .home_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.contact__wrap .home_container
{
	position: absolute;
	left: 0;
	bottom: 118px;
	width: 100%;
}
.contact__wrap .home_title
{
	font-size: 30px;
	font-weight: 900;
	color: #FFFFFF;
}

.breadcrumbs
{
	margin-top: -2px;
}
.breadcrumbs ul li
{
	display: inline-block;
	position: relative;
}
.breadcrumbs ul li:not(:last-child)::after
{
	display: inline-block;
	position: relative;
	content: '/';
	margin-left: 7px;
	margin-right: 2px;
}
.breadcrumbs ul li,
.breadcrumbs ul li a
{
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
img{
    max-width: 100%;
}
ul{
    padding: 0;
}
.contact
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 95px;
	padding-bottom: 95px;
}
.contact_text
{
	margin-top: 34px;
	padding-right: 90px;
}
.contact_about_list
{
	margin-top: 48px;
}
.contact_about_list li:not(:last-child)
{
	margin-bottom: 9px;
}
.contact_about_icon
{
	display: inline-block;
	width: 31px;
	height: 31px;
	background: #ff5722;
	text-align: center;
	vertical-align: middle;
}
.contact_about_icon img
{
	position: absolute;
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.contact_about_list li span
{
	font-size: 14px;
	font-weight: 400;
	color: #696969;
	padding-left: 10px;
}
.contact_form_container
{
	width: 100%;
}
.contact_form
{
	display: block;
	position: relative;
	width: 100%;
}
.contact_input
{
	position: relative;
	width: 100%;
	height: 44px;
	border: none;
	outline: none;
	background: #f5f7fa;
	padding-left: 25px;
}
.contact_form > div:not(:last-child)
{
	margin-bottom: 28px;
}
.input_container::after {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: #283290;
    visibility: hidden;
    transition: all 200ms ease;
}
.box.working_hours{
	margin-bottom: 20px;
}
.input_container:hover::after
{
	visibility: visible;
	opacity: 1;
}
.contact_input::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	font-style: italic;
	color: #696969 !important;
}
.contact_input:-moz-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	font-style: italic;
	color: #696969 !important;
}
.contact_input::-moz-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	font-style: italic;
	color: #696969 !important;
} 
.contact_input:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-weight: 400 !important;
	font-style: italic;
	color: #696969 !important;
}
.contact_input::input-placeholder
{
	font-size: 12px !important;
	font-weight: 400 !important;
	font-style: italic;
	color: #696969 !important;
}
.contact_text_area
{
	height: 160px;
	padding-top: 13px;
}
.contact_button
{
	position: relative;
}
.contact_button:hover span{
	color: white;
}
.contact_button span {
    display: block;
    position: relative;
    font-size: 14px;
    font-weight: 900;
    color: #FFFFFF;
    padding-left: 39px;
    padding-right: 45px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    z-index: 10;
}
.confirm_appoinment .home{
	height: 273px;
}
 textarea.form-control::placeholder{
	color: white;
}